

















































































































/*eslint-disabled*/
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ISideBar, IUser } from '@/types/types';

@Component
export default class SideBar extends Vue {
  @Prop({ type: Boolean, default: true }) drawer!: boolean;
  @Prop({ type: Array, default: () => [], required: true })
  sideBarItems!: Array<ISideBar>;
  @Prop({ type: Object, default: null })
  user!: IUser;

  closer: boolean = this.drawer;
  menu = false;
}
